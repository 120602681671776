import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn} from "@angular/forms";
import {AuthService} from "../../shared/services/auth.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute} from '@angular/router';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {

  form: FormGroup;

  public errorMessage: string = ""

  public hideForm: boolean = false
  public showPassword: boolean = false;
  loader: boolean = false;

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private route: ActivatedRoute, private googleAnalytics: GoogleAnalyticsService) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_new_password: ['', [Validators.required, this.matchValidator('new_password')]]
    });
  }


  matchValidator(matchTo: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const parent = control.parent as FormGroup | null;
      const matchingControl = parent?.get(matchTo);

      if (matchingControl && control.value !== matchingControl.value) {
        return {'mismatch': true};
      }
      return null;
    };
  }

  togglePasswordVisibility(e: Event): void {
    e.preventDefault();
    this.showPassword = !this.showPassword;
  }

  savePassword() {
    this.errorMessage = ''
    this.form.markAllAsTouched()
    if (this.form.invalid) return

    this.spinner.show();
    this.loader = true;

    let {email, new_password, confirm_new_password} = this.form.value

    const token = this.route.snapshot.params['token']

    this.authService.resetPassword({email, new_password, confirm_new_password}, token).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.loader = false;

        if (res.status === 200) {
          this.hideForm = true;
        }
      },
      error => {
        this.spinner.hide();
        this.loader = false;
        this.errorMessage = error.error.errors
      }
    );
  }

  trackButtonClick(btn_name: any) {
    this.form.markAllAsTouched()
    if (this.form.invalid) return
    this.googleAnalytics.trackButtonClick({'button': btn_name, page: 'Forget Password'});
  }
}
