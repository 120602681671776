import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {VisitProductsComponent} from "./visit-products.component";


@NgModule({
  declarations: [
    VisitProductsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    VisitProductsComponent,
  ],

})
export class VisitProductsModule { }
