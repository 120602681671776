import {Component} from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";
import {FormBuilder, FormGroup, ValidatorFn, AbstractControl, Validators} from '@angular/forms';
import {NgxSpinnerService} from "ngx-spinner";
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})

export class ResetPasswordComponent {

  resetForm: FormGroup;

  public errorMessage: string = ""

  public hideForm: boolean = false

  public loader: boolean = false

  constructor(private authService: AuthService, private googleAnalytics: GoogleAnalyticsService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService) {
    this.resetForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ],
      ]
    });
  }

  resetPassword() {
    this.resetForm.markAllAsTouched()
    if (this.resetForm.invalid) return

    let {email} = this.resetForm.value
    this.spinner.show();
    this.loader = true;

    this.authService.forgotPassword({email}).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.spinner.hide()
          this.loader = false
          this.hideForm = true
        }
      },
      error => {
        this.loader = false
        this.spinner.hide()
        this.resetForm.get('email')?.setErrors({apiError: true})
        this.errorMessage = error.error.errors
      }
    );
  }

  trackButtonClick(btn_name: any) {
    this.resetForm.markAllAsTouched()
    if (this.resetForm.invalid) return
    this.googleAnalytics.trackButtonClick({'button': btn_name, page: 'Reset password'});
  }
}
