import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthRoutingModule} from './auth-routing.module';
import {FormsModule} from "@angular/forms";
import {ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from "./login/login.component";
import {RegisterComponent} from "./register/register.component";
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {NgxSpinnerModule} from "ngx-spinner";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";
import {NgbAlertModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from "ngx-toastr";
import {LayoutModule} from "../layout/layout.module";
import {SpinnerModule} from "../shared/components/spinner/spinner.module";
import {VisitProductsModule} from "../shared/components/visit-products/visit-products.module";
import { RestoreAccountComponent } from './restore-account/restore-account.component';
import { CustomPageComponent } from './custom-page/custom-page.component';
import { LogoutComponent } from './logout/logout.component';


@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    RestoreAccountComponent,
    CustomPageComponent,
    LogoutComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgbAlertModule,
    NgbModule,
    LayoutModule,
    SpinnerModule,
    ToastrModule.forRoot(),
    VisitProductsModule
  ]
})
export class AuthModule {
}
