<div class="container">
  <div class="inner-page-banner">
    <img class="banner-image w-100" src="../../../assets/images/banner-image.png" alt="">
    <div class="content">
      <h2 class="banner-title">
        {{pageTitle}}
      </h2>
      <nav aria-label="breadcrumb " class="my-4">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item text-white">Register</li>
          <li class="breadcrumb-item active" aria-current="page">{{pageTitle}}</li>
        </ol>
      </nav>
      <button class="btn btn-outline-light px-5" routerLink="/register" (click)="trackButtonClick('Go back')">
        Go back
      </button>
    </div>
  </div>
  <div [innerHTML]="pageHtml" class="inner-content-site"></div>

</div>
