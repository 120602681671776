import { Component } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from "../../shared/services/auth.service";
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';

@Component({
  selector: 'app-restore-account',
  templateUrl: './restore-account.component.html',
  styleUrl: './restore-account.component.scss'
})
export class RestoreAccountComponent {

  public title = 'Your account has been restore';
  constructor(private authService: AuthService, private route: ActivatedRoute, private googleAnalytics: GoogleAnalyticsService) {this.restoreAccount()}

  restoreAccount() {

    const token = this.route.snapshot.params['token']

    this.authService.restoreAccount(token).subscribe(
      (res: any) => {
        this.title = 'Your account has been restore';
      },
      (err) => {
        this.title = 'Invalid or expired link';
      }
    )

  }

  trackButtonClick(btn_name: any) {
    this.googleAnalytics.trackButtonClick({'button': btn_name, page: 'Restore account'});
  }
}
