import {Component} from '@angular/core';
import {environment} from "../../../../environments/environment";
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
  selector: 'visit-products',
  templateUrl: './visit-products.component.html',
})
export class VisitProductsComponent {

  constructor(private googleAnalytics: GoogleAnalyticsService) {
  }

  trackButtonClick(btn_name: any) {
    this.googleAnalytics.trackButtonClick({'button': btn_name, page: 'VET Times Products'});
  }

  goToProduct(product: string) {
    let product_url: string = '';
    switch (product) {
      case 'Vet Times':
        product_url = environment.vettimesUrl
        break;
      case 'Vet times jobs':
        product_url = environment.myJobsUrl
        break;
      case 'CPD+':
        product_url = environment.vettimesUrl + 'cpd-plus'
        break;
    }
    window.open(product_url);
  }
}
