import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerConfigService {

  bdColor = 'rgba(0, 0, 0, 0)';
  size: 'small' | 'medium' | 'large' = 'small';
  color = '#ffffff';
  type = 'ball-elastic-dots';
  fullScreen = false;
  name = "primary";

  constructor() {
  }
}
