<div class="wrap-auth">
  <div class="row h-100">
    <div class="col-lg-5 p-0">
      <app-layout tagLine="Create your free vet times account" bgImage="../../../assets/images/register-bg.png"></app-layout>
    </div>
    <div class="col-lg-7 p-0 col-12">
      <div class="right-section">
        <div class="right-section-content">
          <h3 class="title d-none" [ngClass]="{'d-md-block' : curr_step == 1 || curr_step == 2}">
            Create your free Vet Times account
          </h3>
          <div class="onboarding-progress-nav">
            <ul>
              <li
                class="progress-nav-item"
                [ngClass]="{
                  focused: curr_step == 1,
                  done: curr_step == 2,
                  'd-none':
                    curr_step == 3 ||
                    curr_step == 4 ||
                    curr_step == 5 ||
                    curr_step == undefined
                }"
              ></li>
              <li
                class="progress-nav-item"
                [ngClass]="{
                  focused: curr_step == 2,
                  'd-none':
                    curr_step == 3 ||
                    curr_step == 4 ||
                    curr_step == 5 ||
                    curr_step == undefined
                }"
              ></li>
            </ul>
          </div>

          <form [formGroup]="form">
            <div
              class="onboarding-card"
              [ngClass]="{ 'd-md-block': curr_step == 1, 'd-none' : curr_step != 1 }"
            >
              <h3 class="onboarding-card-title">Personal details</h3>
              <div>

                <div class="mb-lg-4 mb-3">
                  <label for="name" class="form-label required">First name</label>
                  <input
                    type="text"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid':
                        form.get('name')?.errors && form.get('name')?.touched
                    }"
                    formControlName="name"
                    placeholder=""
                    id="name"
                    maxlength="254"
                  />
                  <div
                    *ngIf="
                      form.get('name')?.hasError('required') &&
                      form.get('name')?.touched
                    "
                    class="invalid-feedback"
                  >
                    Name is required.
                  </div>
                  <div
                    *ngIf="
                    form.get('name')?.hasError('pattern') && form.get('name')?.touched
                  "
                    class="invalid-feedback"
                  >
                    Name can only contain alphabets, one hyphen, one apostrophe, and spaces.
                  </div>
                </div>

                <div class="mb-lg-4 mb-3">
                  <label for="last_name" class="form-label required">Last name</label>
                  <input
                    type="text"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid':
                        form.get('last_name')?.errors &&
                        form.get('last_name')?.touched
                    }"
                    formControlName="last_name"
                    placeholder=""
                    id="last_name"
                    maxlength="254"
                  />
                  <div
                    *ngIf="
                      form.get('last_name')?.hasError('required') &&
                      form.get('last_name')?.touched
                    "
                    class="invalid-feedback"
                  >
                    Last name is required.
                  </div>
                  <div
                    *ngIf="
                  form.get('last_name')?.hasError('pattern') && form.get('last_name')?.touched
                "
                    class="invalid-feedback"
                  >
                    Last name can only contain alphabets, one hyphen, one apostrophe, and spaces.
                  </div>
                </div>

                <div class="mb-lg-4 mb-3 has-validation">
                  <label for="email" class="form-label required">E-mail</label>
                  <input
                    type="email"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid':
                        (form.get('email')?.errors &&
                          form.get('email')?.touched) ||
                        errorMessage.email
                    }"
                    (keyup)="errorMessage.email = ''"
                    placeholder="Please enter your e-mail"
                    formControlName="email"
                    id="email"
                    aria-describedby="emailHelp"
                    maxlength="254"
                    required
                  />
                  <div
                    *ngIf="
                      form.get('email')?.hasError('required') &&
                      form.get('email')?.touched
                    "
                    class="invalid-feedback"
                  >
                    E-mail is required.
                  </div>

                  <div
                    class="invalid-feedback"
                    *ngIf="
                      form.get('email')?.hasError('email') &&
                      form.get('email')?.touched &&
                      !form.get('email')?.hasError('pattern')
                    "
                  >
                    Invalid e-mail address
                  </div>

                  <div
                    class="invalid-feedback"
                    *ngIf="
                      form.get('email')?.hasError('pattern') &&
                      form.get('email')?.touched
                    "
                  >
                    Please enter a correct e-mail.
                  </div>

                  <div *ngIf="errorMessage.email && !form.get('email')?.errors" class="invalid-feedback">
                    {{ errorMessage.email }} <a href='#' class="fw-medium text-primary text-decoration-none"
                                                *ngIf="showResendLink" (click)="resendVerificationEmail($event)">resend
                    the verification e-mail</a>
                  </div>
                </div>

                <div class="mb-lg-4 mb-3 has-validation">
                  <label for="password" class="form-label required">Password</label>
                  <div class="password-wrap">
                    <input
                      type="{{ showPassword ? 'text' : 'password' }}"
                      placeholder="Password"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          form.get('password')?.errors &&
                          form.get('password')?.touched
                      }"
                      formControlName="password"
                      id="password"
                      maxlength="254"
                      required
                    />
                    <a
                      class="show-password active"
                      href="#"
                      (click)="togglePasswordVisibility($event)"
                    >{{ showPassword ? "Hide" : "Show" }}</a
                    >

                    <div
                      *ngIf="
                        form.get('password')?.hasError('required') &&
                        form.get('password')?.touched
                      "
                      class="invalid-feedback"
                    >
                      Password is required.
                    </div>
                    <div
                      *ngIf="
                        form.get('password')?.hasError('minlength') &&
                        form.get('password')?.touched &&
                        !form.get('password')?.hasError('required')
                      "
                      class="invalid-feedback"
                    >
                      Please enter at least 8 character password.
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn btn-auth btn-primary w-100 mt-2"
                  (click)="next(); isDisable(); trackButtonClick({'button': 'Next', page: 'Register'})"
                >
                  <app-spinner></app-spinner>
                  <span *ngIf="!loader">Next</span>
                </button>
              </div>
              <div class="benefits">
                <ul>
                  <li class="benefit-item"><span class="icon"><img src="../../../assets/images/check.png" alt=""></span><p class="mb-0"><strong>Stay informed:</strong> Get the latest veterinary news & research.</p></li>
                  <li class="benefit-item"><span class="icon"><img src="../../../assets/images/check.png" alt=""></span><p  class="mb-0"><strong>Progress your career:</strong> See the latest jobs.</p></li>
                  <li class="benefit-item"><span class="icon"><img src="../../../assets/images/check.png" alt=""></span><p class="mb-0"><strong>Simplify CPD:</strong> Log CPD time & track progress effortlessly.</p></li>
                  <li class="benefit-item"><span class="icon"><img src="../../../assets/images/check.png" alt=""></span><p class="mb-0"><strong>Build your library:</strong> Bookmark key articles for future reference.</p></li>

                </ul>
              </div>

            </div>

            <div
              class="onboarding-card"
              [ngClass]="{ 'd-md-block': curr_step == 2, 'd-none' : curr_step != 2 }"
            >
              <h3 class="onboarding-card-title">Select your role</h3>

              <div>

                <div class="roles-wrap mb-lg-4 mb-3">
                  <a
                    href="#"
                    (click)="selectJobRole($event, 'C'); trackButtonClick({'role': 'Veterinary surgeon', page: 'Register'})"
                    class="role-item"
                    [ngClass]="{ active: form.get('job_role')?.value === 'C' }"
                  >Veterinary surgeon</a
                  >
                  <a
                    href="#"
                    (click)="selectJobRole($event, 'D'); trackButtonClick({'role': 'Registered veterinary nurse', page: 'Register'})"
                    class="role-item"
                    [ngClass]="{ active: form.get('job_role')?.value === 'D' }"
                  >Registered veterinary nurse</a
                  >
                  <a
                    href="#"
                    (click)="selectJobRole($event, 'S'); trackButtonClick({'role': 'Veterinary student', page: 'Register'})"
                    class="role-item"
                    [ngClass]="{ active: form.get('job_role')?.value === 'S' }"
                  >Veterinary student</a
                  >
                  <a
                    href="#"
                    (click)="selectJobRole($event, 'G'); trackButtonClick({'role': 'Student veterinary nurse', page: 'Register'})"
                    class="role-item"
                    [ngClass]="{ active: form.get('job_role')?.value === 'G' }"
                  >Student veterinary nurse</a
                  >
                  <a
                    href="#"
                    (click)="selectJobRole($event, 'O'); trackButtonClick({'role': 'Non-Clinical', page: 'Register'})"
                    class="role-item"
                    [ngClass]="{ active: form.get('job_role')?.value === 'O' }"
                  >Non-Clinical</a
                  >
                </div>

                <div class="mb-lg-4 mb-3">
                  <label for="country_of_residence" class="form-label required"
                  >Country of residence</label>
                  <select
                    class="form-select is-invalid"
                    [ngClass]="{
                      'is-invalid':
                        form.get('country_of_residence')?.errors &&
                        form.get('country_of_residence')?.touched
                    }"
                    aria-label="Default select example"
                    formControlName="country_of_residence"
                    id="country_of_residence"
                    (change)="onCountryChange(); isDisable()"
                  >
                    <option value="" disabled>Please select</option>
                    <option *ngFor="let country of countries"
                            [value]="country.country_name">{{ country.country_name }}</option>
                  </select>

                  <div
                    *ngIf="
                      form.get('country_of_residence')?.errors &&
                      form.get('country_of_residence')?.touched
                    "
                    class="invalid-feedback"
                  >
                    Country of residence is required.
                  </div>
                </div>

                <div class="mb-lg-4 mb-3" *ngIf="showRcvs && rcvsFieldsName">
                  <label for="rcvs" class="form-label">{{rcvsFieldsName}} <a href="#" (click)="findRcvsNumber($event)" *ngIf="rcvsFieldsName == 'RCVS number'">(Find it here)</a>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid':
                        (form.get('rcvs')?.errors &&
                          form.get('rcvs')?.touched) ||
                        errorMessage.rcvs
                    }"
                    formControlName="rcvs"
                    (input)="errorMessage.rcvs = ''; isDisable(); validateRcvsInput($event)"
                    placeholder=""
                    id="rcvs"
                    [attr.maxlength]="rcvsFieldsName == 'RCVS number' ? 7 : 254"
                  />
                  <small class="form-text text-muted">
                    This is needed so that you can see clinical content
                  </small>

                  <div
                    *ngIf="
                      form.get('rcvs')?.hasError('pattern') &&
                      form.get('rcvs')?.touched
                    "
                    class="invalid-feedback"
                  >
                    {{rcvsFieldsName}} is invalid.
                  </div>
                  <div
                    *ngIf="errorMessage.rcvs && !form.get('rcvs')?.errors"
                    class="invalid-feedback"
                  >
                    {{ errorMessage.rcvs }}
                  </div>
                  <div
                    *ngIf="
                      (form.get('rcvs')?.hasError('maxlength') ||
                        form.get('rcvs')?.hasError('minlength')) &&
                      form.get('rcvs')?.touched &&
                      !form.get('rcvs')?.hasError('pattern')
                    "
                    class="invalid-feedback"
                  >
                    {{rcvsFieldsName}} must be exactly 7 characters long.
                  </div>
                </div>

                <div class="form-check mt-3">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    (change)="isDisable()"
                    id="agreeTerms"
                    formControlName="agreeTerms"
                  />
                  <label class="form-label" for="agreeTerms">
                    Please confirm that you are a veterinary professional or work in a related industry to see clinical information.
                  </label>
                </div>

                <div class="d-flex justify-content-between gap-3">
                  <button
                    type="submit"
                    class="btn btn-outline w-100 mt-2"
                    (click)="curr_step = 1"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    class="btn btn-auth btn-primary w-100 mt-2"
                    [disabled]="isDisabled"
                    (click)="onSubmit();  trackButtonClick({'button': 'Create account', page: 'Register'})"
                  >
                    <app-spinner></app-spinner>
                    <span *ngIf="!loader">Create account</span>
                  </button>
                </div>
              </div>
            </div>

          </form>
          <p *ngIf="curr_step === 1 || curr_step === 2" class="text mb-lg-4 mb-3">Do you already have an account? <a routerLink="/login" class="join">Log in</a>
          </p>

          <p *ngIf="curr_step === 1  || curr_step === 2" class="text ">By clicking Create account you agree to our <a href="" routerLink="/terms-and-conditions">Terms and conditions</a> and confirm
            you have read our <a href="" routerLink="/privacy-policy">Privacy Policy</a> and <a href="" routerLink="/cookie-policy">Cookie Policy</a> .</p>

          <div
            class="onboarding-card"
            [ngClass]="{ 'd-md-block': curr_step == 3, 'd-none' : curr_step != 3 }"
          >
            <h3 class="onboarding-card-title">Please verify your e-mail</h3>

            <div class="content">
              <div class="icon-wrap">
                <img src="../../../assets/images/email-icon.svg" alt=""/>
              </div>
              <p class="text">
                We have sent e-mail to <strong>{{ form.value.email }}</strong> for
                e-mail address confirmation; also, check spam folder. Click link
                in received e-mail to complete registration.
              </p>
              <hr/>
              <p class="text mb-0 btn-auth">
                <app-spinner color="black"></app-spinner>
                <span *ngIf="!loader">Didn’t receive the e-mail?
                  <a href="#" class="fw-medium text-primary text-decoration-none"
                     (click)="resendVerificationEmail($event)">Click to resend confirmation mail</a></span>

              </p>
            </div>
          </div>

          <div
            class="onboarding-card"
            [ngClass]="{ 'd-md-block': curr_step == 4, 'd-none' : curr_step != 4 }"
          >
            <h3 class="onboarding-card-title">Link expired or already used</h3>

            <div class="content">
              <div class="icon-wrap">
                <img
                  src="../../../assets/images/invalid-email-icon.svg"
                  alt=""
                />
              </div>
              <p class="text ">
                We're sorry, the verification link is expired or has already been used. If you already have an account,
                please log in. If you don't have an account yet, you can sign up for one.
              </p>
            </div>
            <div class="d-flex justify-content-between gap-3">
              <a
                href="" routerLink="/login"
                class="btn btn-outline w-100 mt-lg-3 mt-2">
                Login
              </a>
              <a href="" routerLink="/register"
                 class="btn btn-auth btn-primary w-100 mt-lg-3 mt-2">
                Sign up
              </a>

            </div>
          </div>

          <div
            class="onboarding-card"
            [ngClass]="{ 'd-md-block': curr_step == 5, 'd-none' : curr_step != 5 }"
          >
            <h3 class="onboarding-card-title">Your account has been created</h3>

            <div class="content">
              <div class="icon-wrap">
                <img
                  src="../../../assets/images/account-created-icon.svg"
                  alt=""
                />
              </div>
              <p class="text text-dark fw-medium">
                Stay up to date with veterinary news.
              </p>
              <a href="" routerLink="/subscription/email"
                 class="btn btn-primary d-block mx-auto mt-lg-2 mt-0" (click)="trackButtonClick({'button': 'Customise your subscription', page: 'Register'})">
                Customise your subscription
              </a>
            </div>
          </div>

          <div [ngClass]="{ 'd-md-block': curr_step == 5, 'd-none' : curr_step != 5 }">
            <visit-products></visit-products>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
