import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {RegisterComponent} from "./register/register.component";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";
import {AuthGuard} from "../shared/guard/auth.guard";
import {RestoreAccountComponent} from './restore-account/restore-account.component';
import {CustomPageComponent} from "./custom-page/custom-page.component";
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent, canActivate: [AuthGuard]},
  {path: 'logout', component: LogoutComponent},
  {path: 'register', component: RegisterComponent,canActivate:[AuthGuard]},
  {path: 'reset/password', component: ResetPasswordComponent},
  {path: 'reset/password/:token', component: ForgotPasswordComponent},
  {path: 'restore/account/:token', component: RestoreAccountComponent},
  {path: 'terms-and-conditions', component: CustomPageComponent},
  {path: 'cookie-policy', component: CustomPageComponent},
  {path: 'privacy-policy', component: CustomPageComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
