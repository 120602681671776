import {Component, Input} from '@angular/core';
import {SpinnerConfigService} from '../../services/spinner-config.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
})
export class SpinnerComponent {
  @Input() bdColor: any;
  @Input() size: any;
  @Input() color: any;
  @Input() type: any;
  @Input() fullScreen: any;
  @Input() name: any;

  constructor(public spinnerConfig: SpinnerConfigService) {
  }

  ngOnInit(): void {
    // Assign default values from the service if not provided by inputs
    this.bdColor = this.bdColor || this.spinnerConfig.bdColor;
    this.size = this.size || this.spinnerConfig.size;
    this.color = this.color || this.spinnerConfig.color;
    this.type = this.type || this.spinnerConfig.type;
    this.fullScreen = this.fullScreen || this.spinnerConfig.fullScreen;
    this.name = this.name || this.spinnerConfig.name;
  }
}
