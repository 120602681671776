<div class="wrap-auth">
  <div class="row h-100">
    <div class="col-lg-5 p-0">
      <app-layout bgImage="../../../assets/images/register-bg.png"></app-layout>
    </div>
    <div class="col-lg-7 p-0 col-12">
      <div class="right-section">
        <div class="right-section-content">
          <div
            class="onboarding-card d-md-block"
          >
            <h3 class="onboarding-card-title">{{title}}</h3>

            <div class="content">
              <div class="icon-wrap">
                <img
                  src="../../../assets/images/account-created-icon.svg"
                  alt=""
                />
              </div>
              <p class="text text-dark fw-medium">
                Stay up to date with veterinary news.
              </p>
              <a href="" routerLink="/login"
                 class="btn btn-primary d-block mx-auto mt-lg-2 mt-0"  (click)="trackButtonClick('Login')">
                Login
              </a>
            </div>
          </div>

          <div class="d-md-block">
            <visit-products></visit-products>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
