<div class="product-card mb-4">
  <p class="text text-dark fw-medium">
    You can now visit any of our products
  </p>
  <div class="product-actions">
    <button class="btn btn-outline" (click)="trackButtonClick('Vet Times'); goToProduct('Vet Times')">Vet Times</button>
    <button class="btn btn-outline" (click)="trackButtonClick('Vet Times jobs'); goToProduct('Vet times jobs')">Vet Times jobs</button>
    <button class="btn btn-outline" (click)="trackButtonClick('CPD+'); goToProduct('CPD+')">CPD+</button>
  </div>
</div>
