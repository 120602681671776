import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {map} from "rxjs";

@Injectable({
  providedIn: 'root',
})

export class CustomPageService {
  
  constructor(private apiService: ApiService) {}

  getCustomPage(page: any) {
    return this.apiService.httpPostMyAccount('page/custom', page).pipe(map(response => {
      const {value} = (response as any).data;
      return value;
    }));
  }
}
