import {Component} from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CookieService} from "ngx-cookie-service";
import {NgxSpinnerService} from "ngx-spinner";
import {environment} from "../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})

export class LoginComponent {

  loginForm: FormGroup;
  showPassword: boolean = false;
  loader: boolean = false;
  redirectTo: string | null = null;
  public errorMessage: string = ""

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private googleAnalytics: GoogleAnalyticsService, private cookieService: CookieService, private spinner: NgxSpinnerService, private activatedRoute: ActivatedRoute) {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ],
      ],
      password: [
        '',
        [
          Validators.required
        ],
      ],
    });
  }


  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.redirectTo = params['redirectTo'];
    });
  }

  togglePasswordVisibility(e: Event): void {
    e.preventDefault();
    this.showPassword = !this.showPassword;
  }

  login() {

    this.errorMessage = ""
    this.loginForm.markAllAsTouched()
    if (this.loginForm.invalid) return

    this.spinner.show();
    this.loader = true;
    let {email, password} = this.loginForm.value;
    password = password.trim();

    this.authService.login({email, password}).subscribe(
      (res: any) => {
        window.location.href = this.redirectTo ?? environment.vettimesUrl;
      },
      error => {
        this.spinner.hide();
        this.loader = false
        this.errorMessage = error.error.errors
      }
    );
  }

  trackButtonClick(btn_name: any) {
    if (btn_name === 'Login') {
      this.loginForm.markAllAsTouched()
      if (this.loginForm.invalid) return
    }
    this.googleAnalytics.trackButtonClick({'button': btn_name, page: 'login'});
  }

  clearErrorMessage() {
    this.errorMessage = ""
  }
}
