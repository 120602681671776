<div class="wrap-auth">
  <div class="row h-100">
    <div class="col-lg-5 p-0">
      <app-layout bgImage="../../../assets/images/forget-bg.png"></app-layout>
    </div>
    <div class="col-lg-7 p-0 col-12 ">
      <div class="right-section">
        <div class="right-section-content">
          <ngb-alert class="auth-alert" type="danger" *ngIf="errorMessage"><div class="d-flex align-items-center"><span class="me-2"><img width="32" src="../../../assets/images/invalid-icon.svg" alt="">
          </span> {{errorMessage}}</div></ngb-alert>
          <div class="onboarding-card" [ngClass]="{ 'd-none': hideForm }">
            <h3 class="onboarding-card-title">Please enter new password</h3>
            <div class="content">
              <div class="icon-wrap">
                <img src="../../../assets/images/email-icon.svg" alt="">
              </div>
              <p class="text">Enter your new password and get back to your veterinary professional community.</p>
              <form [formGroup]="form" action="#" class="onboarding-form needs-validation"
                    novalidate>
                <div class="mb-lg-4 mb-3 has-validation">
                  <label for="exampleInputEmail1" class="form-label">E-mail</label>

                  <input type="email" maxlength="255" formControlName="email" class="form-control"
                         [ngClass]="{ 'is-invalid': (form.get('email')?.touched && form.get('email')?.errors) }"
                         placeholder="Please enter your e-mail"
                         id="exampleInputEmail1" aria-describedby="emailHelp" required>


                  <div class="invalid-feedback" *ngIf="form.get('email')?.hasError('email') && !form.get('email')?.hasError('pattern') ">
                    Invalid e-mail address.
                  </div>

                  <div class="invalid-feedback" *ngIf="form.get('email')?.hasError('required')">
                    E-mail is required
                  </div>

                  <div class="invalid-feedback" *ngIf="form.get('email')?.hasError('pattern')">
                    Please enter a correct e-mail.
                  </div>

                </div>
                <div>
                  <div class="mb-lg-4 mb-3 has-validation">
                    <label class="form-label">Enter new password</label>
                    <div class="password-wrap">

                    <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="new_password" class="form-control"
                           [ngClass]="{ 'is-invalid': (form.get('new_password')?.touched && form.get('new_password')?.errors)  }"
                           required>
                           <a
                           class="show-password active"
                           href="#"
                           (click)="togglePasswordVisibility($event)"
                           >{{ showPassword ? "Hide" : "Show" }}</a
                         >

                    <div class="invalid-feedback" *ngIf="form.get('new_password')?.hasError('required')">
                      Password is required
                    </div>

                    <div class="invalid-feedback" *ngIf="form.get('new_password')?.hasError('minlength')">
                      Please enter at least 8 character password.
                    </div>
                  </div>

                  </div>
                  <div class="mb-3 has-validation">
                    <label for="exampleInputPassword1" class="form-label">Confirm new password</label>
                    <div class="password-wrap">
                      <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="confirm_new_password" class="form-control"
                             [ngClass]="{ 'is-invalid': (form.get('confirm_new_password')?.touched && form.get('confirm_new_password')?.errors)  }"
                             id="exampleInputPassword1" required>
                    <a
                      class="show-password active"
                      href="#"
                      (click)="togglePasswordVisibility($event)"
                      >{{ showPassword ? "Hide" : "Show" }}</a
                    >

                      <div class="invalid-feedback" *ngIf="form.get('confirm_new_password')?.hasError('required')">
                        Confirm new password is required
                      </div>

                      <div class="invalid-feedback"
                           *ngIf="form.get('confirm_new_password')?.hasError('mismatch') && !form.get('confirm_new_password')?.hasError('required')">
                           Password Doesn't Match
                      </div>

                    </div>
                  </div>
                  <button type="submit" class="btn btn-auth btn-primary w-100" (click)="savePassword();  trackButtonClick('Save Password')">
                  <app-spinner></app-spinner>
                  <span *ngIf="!loader">Save Password</span>
                  </button>




                </div>
              </form>
            </div>
          </div>
          <div class="onboarding-card" [ngClass]="{ 'd-none': !hideForm }">
            <h3 class="onboarding-card-title">Your password has been changed successfully</h3>
            <div class="content">
              <div class="icon-wrap">
                <img src="../../../assets/images/password-icon.svg" alt="">
              </div>
              <button class="btn btn-primary w-100" routerLink="/login">Back to login</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
