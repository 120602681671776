import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss'
})
export class LogoutComponent {

  redirectTo: string | null = null;
  constructor(private cookieService: CookieService, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.redirectTo = params['redirectTo'];
      this.logout();
    });
  }

  logout() {
      this.cookieService.delete('sso_signed', '/', '.vettimes.co.uk');
      localStorage.removeItem('user');
      window.location.href = this.redirectTo ?? environment.vettimesUrl;
  }

}
