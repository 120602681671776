import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {

  @Input() bgImage!: string;

  @Input() tagLine: string = '';


  get bgImageMobile(): string {
    if (this.bgImage) {
      const extensionIndex = this.bgImage.lastIndexOf('.');
      return this.bgImage.slice(0, extensionIndex) + '-mobile' + this.bgImage.slice(extensionIndex);
    }
    return '';
  }

}
