<div class="left-section">
  <img class="login-bg" [src]="bgImage" alt="">
  <img class="login-bg mobile d-lg-none" [src]="bgImageMobile" alt="">
  <div class="logo">
  </div>
  <div class="left-section-content">
    <h1 class="welcome-title">Welcome to <br class="d-lg-block d-none"> vet times </h1>
    <p class="welcome-text">Your gateway to the world of veterinary excellence</p>
    <p class="welcome-text sm">{{tagLine}}</p>
  </div>
</div>
