import {Injectable} from '@angular/core';
import { environment } from '../../../environments/environment';
declare var gtag: Function; // Declaring gtag function

@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService {
  constructor() {}

  trackButtonClick(eventModel: any) {
    gtag('event', environment.googleAnalyticsKey, eventModel);
  }
}
