import {Component} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {CustomPageService} from "../../shared/services/custom-page.service";
import {NavigationEnd, Router} from "@angular/router";
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';

@Component({
  selector: 'app-custom-page',
  templateUrl: './custom-page.component.html',
  styleUrl: './custom-page.component.scss'
})
export class CustomPageComponent {

  pageHtml!: SafeHtml;

  pageTitle: string = ""

  page: string = ""

  currentRoute: string = this.router.url;

  constructor(private customPageService: CustomPageService, private sanitizer: DomSanitizer, private router: Router, private googleAnalytics: GoogleAnalyticsService) {
  }

  ngOnInit(): void {

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
      }
    });

    this.page = this.currentRoute.replace('/', '').replaceAll('-', ' ');

    this.pageTitle = this.page.charAt(0).toUpperCase() + this.page.slice(1);

    this.loadPage()
  }

  loadPage() {
    this.page = this.page.replaceAll(' ','_')
    this.customPageService.getCustomPage({page: this.page}).subscribe((rawHtml: any) => {
      this.pageHtml = this.sanitizer.bypassSecurityTrustHtml(rawHtml);
    })
  }

  trackButtonClick(btn_name: any) {
    this.googleAnalytics.trackButtonClick({'button': btn_name, page: this.pageTitle});
  }
}
