import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})

export class LocationService {


  constructor(private http: HttpClient) {
  }

  getLocation(ipAddress: any): Observable<any> {
    return this.http.get(environment.myAccountBaseUrl + "location?ip=" + ipAddress);
  }

  getIpAddress(): Observable<any> {
    return this.http.get('https://api.ipify.org/?format=json');
  }
}
