<div class="wrap-auth">
  <div class="row h-100">
    <div class="col-lg-5 p-0">
      <app-layout bgImage="../../../assets/images/forget-bg.png"></app-layout>
    </div>
    <div class="col-lg-7 p-0 col-12 ">
      <div class="right-section">
        <div class="right-section-content">
          <div class="onboarding-card" [ngClass]="{ 'd-none': hideForm }">
            <h3 class="onboarding-card-title">Reset password</h3>
            <div class="content">
              <div class="icon-wrap">
                <img src="../../../assets/images/password-icon.svg" alt="">
              </div>
              <p class="text">Enter your e-mail below and we’ll send you a link to reset your password</p>
              <form [formGroup]="resetForm" action="#" class="onboarding-form needs-validation"
                    novalidate>
                <div>
                  <div class="mb-lg-4 mb-3 has-validation">
                    <label for="exampleInputEmail1" class="form-label">E-mail</label>

                    <input type="email" maxlength="255" formControlName="email" class="form-control"
                           [ngClass]="{ 'is-invalid': (resetForm.get('email')?.touched && resetForm.get('email')?.errors) }"
                           placeholder="Please enter your e-mail"
                           id="exampleInputEmail1" aria-describedby="emailHelp" required>


                    <div class="invalid-feedback"
                         *ngIf="resetForm.get('email')?.hasError('email') && !resetForm.get('email')?.hasError('pattern')">
                      Invalid e-mail address
                    </div>

                    <div class="invalid-feedback"
                         *ngIf="resetForm.get('email')?.hasError('apiError') && resetForm.get('email')?.touched">
                      {{errorMessage}}
                    </div>

                    <div class="invalid-feedback" *ngIf="resetForm.get('email')?.hasError('required')">
                      E-mail is required
                    </div>

                    <div class="invalid-feedback" *ngIf="resetForm.get('email')?.hasError('pattern')">
                      Please enter a correct e-mail.
                    </div>

                  </div>
                  <button type="submit" class="btn btn-auth btn-primary w-100" (click)="resetPassword(); trackButtonClick('Send password reset link');">
                    <span *ngIf="!loader">Send password reset link</span>

                    <app-spinner></app-spinner>

                  </button>
                </div>
              </form>
            </div>
          </div>


          <div class="onboarding-card" [ngClass]="{ 'd-none': !hideForm }">
            <h3 class="onboarding-card-title">Reset password e-mail</h3>
            <div class="content">
              <div class="icon-wrap">
                <img src="../../../assets/images/email-icon.svg" alt="">
              </div>
              <p class="text">We sent a password reset link to {{resetForm.get('email')?.value}} please
                allow up to 5
                minutes for the e-mail to arrive.</p>
              <hr>
              <p class="text mb-0"> If you are unable to access your account, please contact support&#64;vbd.co.uk</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
